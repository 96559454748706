.landingpagecolor{
    background: rgb(38,172,225);
    background: linear-gradient(
            240deg
            , rgba(38,172,225,1) -10%, rgba(98,71,165,1) 100%);
    width: 100%;
    height: 100%;
}
.landingpage-container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1280px;
    justify-content: center;
    align-content: center;
    height: 100%;
    margin: 0 auto;
}
.landingpage-container form {
    width: 100%;
    max-width: 400px;
    height: 100%;
    box-sizing: border-box;
}
.img-sesame-container {
    display: flex;
    height: 180px !important;
    flex-direction: row !important;
}
.img-sesame-container img {
    object-fit: contain;
    margin-right: 20px;
}
.landingpage-container form div   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
}
.logo-form {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.landingpage-container form div label {
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: white

}
.landingpage-container form div input {
    padding: 12px 18px;
    border: 0;
    border-bottom: 1px solid white;
    background: transparent;
    color: white;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
    box-sizing: border-box;
    border-radius:  0px;
 }
.landingpage-container form div input:focus {
    outline: none;
}
.landingpage-container form div button, .landingpage-container form div .btnS {
    background: white;
    color: black;
    border: 0;
    padding: 12px 20px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 40px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    text-decoration:underline;
}
.landingpage-container form div button:hover {
    text-decoration: none;
}
