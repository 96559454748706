.dashboard-page {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    box-sizing: border-box;
}
#client-list {
    border-top: 1px solid black;
    padding: 0;
}
#client-list li {
    background: white;
    list-style: none;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    flex-direction: column;
    padding-top: 0px;
    cursor: pointer;
}
h1 {
    font-style: italic;
    font-size: 24px;
}
button {
    background: linear-gradient(
            240deg
            , rgba(38,172,225,1) -10%, rgba(98,71,165,1) 100%);
    color: white;
    border: 0;
    padding: 12px 24px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 40px;

}


.new-client {
    position: fixed;
    right: -50%;
    height: 100%;
    top:0;
    width: 100%;
    max-width: 360px;
    box-shadow: -4px 4px 12px rgba(0,0,0,0.2);
    padding: 40px;
    box-sizing: border-box;
    background:white;
    display: flex;
    opacity: 0;
    visibility: hidden;
    justify-content: center;
    align-content: center;
    transition: all 0.2s linear;
    flex-direction: column;

}
.new-client.active {
    opacity: 1;
    visibility: visible;
    right: 0;
    z-index: 999999;
}
#close-client-new{
    position: absolute;
    right: 40px;
    top: 20px;
    cursor: pointer;
}
#close-client-new svg {
    width: 40px;
    transform: rotate(45deg);
}
.new-client form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}
.new-client form h2 {
    width: 100%;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 600;
    color: #0c0c3b;
}
.new-client form label {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.new-client form label input,  .new-client form label select{
    border: 0;
    border-bottom: 1px solid black;
    padding: 6px;
    box-sizing: border-box;
    margin-top: 5px;
}
.new-client button {
    font-size: 20px;
}


.new-appointment {
    position: fixed;
    right: -100%;
    height: 100%;
    top:0;
    width: 100%;
    box-shadow: -4px 4px 12px rgba(0,0,0,0.2);
    padding: 40px;
    box-sizing: border-box;
    background:white;
    display: flex;
    opacity: 0;
    visibility: hidden;
    justify-content: center;
    align-content: center;
    transition: all 0.2s linear;
    z-index: 999999;
}
.line-scontainer{
    text-align: center;
    margin: 0px 0px 20px;
    width: 100% !important;
    display: flex  !important;
    flex-direction: row  !important;
    justify-content: space-around  !important;
    align-items: center  !important;
}
.line-s {
    width: 100%;
    max-width: 140px;
    height: 2px;
    display: block;
    background: black;
}
.new-appointment.active {
    opacity: 1;
    visibility: visible;
    right: 0;
}
#close-new-appointment{
    position: absolute;
    right: 40px;
    top: 20px;
    cursor: pointer;
}
#close-new-appointment svg {
    width: 20px;
    transform: rotate(45deg);
    cursor: pointer;
}
.new-appointment form, .form-edit-marc {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    overflow: hidden;
    overflow-y: scroll;
    padding-top: 10px;
}

.new-appointment form::-webkit-scrollbar {
    width: 12px;
    display: none;
}

.new-appointment form::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    display: none;
}
.new-appointment form::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    display: none;
}

.new-appointment form h2, .form-edit-marc h2 {
    width: 100%;
    font-style: italic;
    font-weight: 600;
    color: #0c0c3b;
}
.new-appointment form h4, .form-edit-marc h4 {
    width: 100%;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 600;
    color: #0c0c3b;
    opacity: 0.8;
}
.new-appointment form label , .form-edit-marc label{
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
}
.new-appointment form label select, .form-edit-marc label select{
    width: 100%;
    background: transparent;
    padding: 6px 20px;
    margin-top: 10px;
    border: 2px solid #0c0c3b
}
.new-appointment form label select:focus , .form-edit-marc label select:focus{
    outline: none;
}
.new-appointment form label input , .form-edit-marc label input{
    margin-top: 10px;
    min-height: 32px !important;
    padding: 0 20px !important;
    margin-top: 5px !important;
}
.new-appointment button, .form-edit-marc button {
    font-size: 12px;
    padding: 0px 20px;
    min-height: 30px;
}
.new-appointment form label ul, .form-edit-marc label ul{
    display: none;
    max-height: 120px;
    overflow-y: scroll;
    padding: 20px 0;
    box-sizing: border-box;
    padding-right: 5px;
}
.new-appointment form label ul.search-ul.active , .form-edit-marc label ul.search-ul.active{
    display: block;
    width: 100%;
    max-height: 120px;
}
.new-appointment form label ul.search-ul.active li:hover {
    background: rgba(0,0,0,0.05);
    border-radius: 0;
}
.new-appointment form label ul::-webkit-scrollbar {
    width: 6px;
}

.new-appointment form label ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #0c0c3b;
    border-radius: 10px;
}
.new-appointment form label ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #0c0c3b;
}
.new-appointment form label ul li {
    list-style: none;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 40px;
    text-align: center;
}
.new-appointment form label ul li.active {
    background: #0c0c3b;
    color: white;
}
.new-appointment form label textarea , .form-edit-marc label textarea {
    width: 100%;
    background: transparent;
    border: 2px solid;
    border-radius: 40px;
    margin-top: 10px;
    padding: 4px 16px;
}
.delete-btn, .updated-btn {
    width: auto;
    margin-top: 20px;
    display: block;
    text-align: right;
    padding-bottom: 0px;
    color: #0c0c3b;
    border-bottom: 2px solid #0c0c3b;
    text-decoration: none;
}
.custom-time {
    border: 2px solid #0c0c3b;
    text-decoration: none;
    padding: 12px;
    line-height: 20px;
    margin: 20px 0;
    display: block;
    width: auto;
    position: relative;
    color: #0c0c3b;
    text-align: center;
}
