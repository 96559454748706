header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
    background: linear-gradient(
            240deg
            , rgba(38,172,225,1) -10%, rgba(98,71,165,1) 100%);
}
.container-header ul div:first-child {
    margin-right: 20px;
}
.custom-select {
    position: relative;
    font-family: 'Montserrat', sans-serif;
}

 .select-lang {
     background: transparent;
     color: white;
     border: 0;
 }
.select-lang option{
    color: black;
}
.select-selected {
    background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}
.container-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    padding: 10px;
    box-sizing: border-box;
}
.container-header .logo-header{
    width: 100%;
    max-width: 200px;
}
.container-header ul {
    display: flex;
    align-items:center ;
}
.container-header ul li {
    list-style: none;
    margin-right: 20px;

}
.container-header ul li a {
    color: white;
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 0;
}
.container-header ul li a:hover {
    border-bottom: 1px solid white;
}
.container-header button {
    background: transparent;
    border:0;
    color: white;
    font-weight: 600;
}

.filter-container {
    background: transparent;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 40px;
}

.filter-container input, .filter-container select {
    border-radius: 40px;
    background: white;
    border: 0;
    padding: 12px 18px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 2px 4px rgba(0 ,0 ,0 ,0.1);
}
.filter-container input:focus {
    outline: none;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0 !important;
    flex-wrap: wrap;
}
.pagination li {
    margin-bottom: 20px;
    list-style: none;
}
.pagination a {
    color: rgba(98,71,165,1);
    cursor: pointer;
    padding: 5px 15px;
    box-sizing: border-box;
    border: 1px solid rgba(98,71,165,1);
    margin-right: 5px;
}
.pagination li.active a{
    background: linear-gradient(
            240deg
            , rgba(38,172,225,1) -10%, rgba(98,71,165,1) 100%);
    color: white;
}
